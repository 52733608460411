import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBanner from '../components/share/brandBanner'
import TypeFormButton from '../components/share/typeFormButton'
import ContentWrapper from '../components/share/contentWrapper'
import FeatureBox from '../components/share/featureBox'
import GreyBackground from '../components/share/greyBackground'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'
import ProductBoxes from '../components/share/productBoxes'
import Quote from '../components/share/quote'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "socialMedia/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerPhone: file(relativePath: { eq: "share/headerPhone.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    jasonGirzada: file(
      relativePath: { eq: "brandAgentIcon/jason_girzada.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    platform: file(relativePath: { eq: "socialMedia/platform.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    socialCount: file(relativePath: { eq: "socialMedia/socialCount.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    joshHart: file(relativePath: { eq: "brandAgentIcon/josh_hart.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    benCollier: file(relativePath: { eq: "brandAgentIcon/ben_collier.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    monikaTu: file(relativePath: { eq: "brandAgentIcon/monika_tu.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TheAgency: file(relativePath: { eq: "brandLogos/theAgency.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mountain: file(relativePath: { eq: "socialMedia/mountainclimber.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tomPanos: file(relativePath: { eq: "brandAgentIcon/tom_panos.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    moonAndMountain: file(
      relativePath: { eq: "socialMedia/MoonAndMountain.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    joshPhegan: file(relativePath: { eq: "brandAgentIcon/josh_phegan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    powerful: file(relativePath: { eq: "socialMedia/powerful.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    connectedAndIntegrated: file(
      relativePath: { eq: "socialMedia/connectedAndIntegrated.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    budgeting: file(relativePath: { eq: "socialMedia/budgeting.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    reporting: file(relativePath: { eq: "socialMedia/reporting.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    yourself: file(relativePath: { eq: "socialMedia/yourself.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    pin: file(relativePath: { eq: "socialMedia/pin.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    integration: file(relativePath: { eq: "socialMedia/integration.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    singleImage: file(relativePath: { eq: "socialMedia/singleImage.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    displayAds: file(relativePath: { eq: "socialMedia/ads_square.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    carouselAds: file(relativePath: { eq: "socialMedia/ads_multiple.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    unlimitedUpdates: file(
      relativePath: { eq: "socialMedia/unlimitedUpdates.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    multipleNetworks: file(
      relativePath: { eq: "socialMedia/multipleNetworks.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    coorparoo: file(relativePath: { eq: "socialMedia/coorparoo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coorparooGraph: file(
      relativePath: { eq: "socialMedia/coorparooGraph.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    narrabeenGraph: file(
      relativePath: { eq: "socialMedia/narrabeenGraph.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    johnCunningham: file(
      relativePath: { eq: "brandAgentIcon/john_cunningham.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    narrabeen: file(relativePath: { eq: "socialMedia/narrabeen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

class SocialMediaPage extends Component {
  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                style={{ height: '600px' }}
                opacity="0.5"
                skew
              >
                <ContentWrapper>
                  <HeaderTitle>
                    The market’s moved <br />
                    <strong>Follow or Fail</strong>
                  </HeaderTitle>

                  <HeaderBody>
                    Easy to use, a{' '}
                    <strong>DIY social advertising platform</strong>, that grows
                    your following, and exposes <strong>your properties</strong>{' '}
                    to over 17 million Australians. Putting{' '}
                    <strong>you back in control</strong> of your marketing..
                    Social media mastery for professional agencies, just like
                    yours.
                  </HeaderBody>

                  <HeaderLink>
                    Your expertise, our technology
                    <Icon icon={['fal', 'arrow-down']} />
                  </HeaderLink>
                </ContentWrapper>

                <HeaderPhoneImg
                  fluid={data.headerPhone.childImageSharp.fluid}
                  style={{ position: 'absolute' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ImageBackground>

              <ContentWrapper style={{ marginTop: '90px' }}>
                <IntroRow
                  title="Agents who don’t adapt and change to the market are doomed to fail."
                  content={
                    <Fragment>
                      <Paragraph>
                        Millenials make up{' '}
                        <strong>66 percent of the market</strong> for first-time
                        homebuyers. <strong>99% of buyers</strong> se the
                        internet when looking to purchase a new house. Of the{' '}
                        <strong>25+ million Australians,</strong> 15 million of
                        them use Facebook and Youtube you can’t afford to be
                        left out.
                      </Paragraph>

                      <Paragraph>
                        It’s not enough to just list your property on real
                        estate portals as passive buyers aren’t there.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <Quote
                  agentImage={data.jasonGirzada.childImageSharp.fluid}
                  desc={`“Organizations are being forced to accelerate their pace of adaption to avoid losing ground in the market.”`}
                  agencyName="Deloitte"
                  agentName="Jason Girzada"
                  title="Analyst"
                />
              </ContentWrapper>

              <ImageBackground
                background={data.platform.childImageSharp.fluid}
                height="550px"
                opacity="0.5"
                style={{ margin: 0 }}
              >
                <ContentWrapper style={{ margin: '25px 0 25px 0' }}>
                  <IntroRow
                    descWidth="50%"
                    image={data.socialCount.childImageSharp.fluid}
                    title="Take control of your brand, where the market is. And dominate their attention with a digital-first mindset."
                    content={
                      <Fragment>
                        <Paragraph>
                          The rise of social media, has entirely changed the
                          face of marketing as we know it. Old methods stop
                          working, new ones pop up almost daily.
                        </Paragraph>

                        <Paragraph>
                          Staying ahead of the curve means accepting that change
                          is inevitable and that your strategies are built to
                          compensate for this.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <GreyBackground style={{ margin: 0, padding: '10px 0' }}>
                <ContentWrapper style={{ alignItems: 'center' }}>
                  <IntroRow
                    descWidth="60%"
                    title={
                      <strong>
                        There’s leaders out there, already taking advantage of
                        this new landscape.
                      </strong>
                    }
                    content={
                      <Fragment>
                        <Paragraph>
                          If you want to know how to succeed, they say look at
                          those who are successful and break down what they’re
                          doing.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                  <Flex style={{ marginTop: 30 }}>
                    <FeatureBox
                      image={data.joshHart.childImageSharp.fluid}
                      imageRadius
                      title="$155m"
                      subTitle="Worth of property transactions."
                      content={
                        <Fragment>
                          <Paragraph>
                            <strong>Josh Hart</strong> 27 year old Launceston
                            agent.
                          </Paragraph>
                          <Paragraph>
                            Writes <strong> $1 Million in GCI </strong> in an
                            area where the average house price is just $325,000.
                          </Paragraph>
                          <Paragraph>
                            Uses his social media to build authority, prospect
                            24/7 and dominate his local marketplace across
                            Launceston.
                          </Paragraph>
                        </Fragment>
                      }
                    />
                    <FeatureBox
                      image={data.benCollier.childImageSharp.fluid}
                      imageRadius
                      title="$36m"
                      subTitle="Highest individual sale price"
                      content={
                        <Fragment>
                          <Paragraph>
                            <strong>Ben Collier</strong> record holding Sydney
                            Agent.
                          </Paragraph>
                          <Paragraph>
                            Sells property at record highs in Vaucluse and
                            Centennial park for{' '}
                            <strong>$15.3m and $12m respectively.</strong>
                          </Paragraph>
                          <Paragraph>
                            Uses targeting and activity to target passive buyers
                            relevant to his properties and win more listings
                            with sellers.
                          </Paragraph>
                        </Fragment>
                      }
                    />

                    <FeatureBox
                      image={data.monikaTu.childImageSharp.fluid}
                      imageRadius
                      title="$120m"
                      subTitle="in sales during 2017"
                      content={
                        <Fragment>
                          <Paragraph>
                            <strong>Monika Tu</strong> hugely influencial Sydney
                            agent.
                          </Paragraph>
                          <Paragraph>
                            Recently featured on China Central Television to an
                            audience of <strong>1.3 Billion people.</strong>
                          </Paragraph>
                          <Paragraph>
                            Constantly innovating and reaching audiences across
                            Facebook, Linkedin, WeChat and WhatsApp.
                          </Paragraph>
                        </Fragment>
                      }
                    />
                  </Flex>
                </ContentWrapper>
              </GreyBackground>
              <ContentWrapper>
                <Quote
                  agentImage={data.benCollier.childImageSharp.fluid}
                  desc={`“On listing portals, we are waiting for people to come to us, but when we go to social media we are going looking for the people - people who we know are interested in buying a specific home - before they are actively looking.”`}
                  agencyImage={data.TheAgency.childImageSharp.fluid}
                  agentName="Ben Collier"
                  title="Owner"
                />
              </ContentWrapper>

              <ImageBackground
                background={data.mountain.childImageSharp.fluid}
                height="500px"
                opacity="0.5"
                imgStyle={{ objectPosition: 'left' }}
                style={{ margin: 0 }}
              >
                <ContentWrapper style={{ margin: '90px 0 0 0' }}>
                  <BannerH4 style={{ textAlign: 'start', marginBottom: 30 }}>
                    If you’re going to try and climb this <br />
                    mountain, you’ll <strong>need the right tools.</strong>
                  </BannerH4>

                  <Quote
                    agentImage={data.tomPanos.childImageSharp.fluid}
                    style={{ color: 'white' }}
                    descStyle={{ color: 'white' }}
                    desc={`“Manage your own social media profile with pride. It’s your own brand. Be human, and be real.”`}
                    agentName="Tom Panos"
                    agencyName="Real Estate Trainer"
                  />
                </ContentWrapper>

                <MoonImg
                  fluid={data.moonAndMountain.childImageSharp.fluid}
                  style={{ position: 'absolute' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ImageBackground>

              <ContentWrapper style={{ marginTop: 120 }}>
                <IntroRow
                  title="Be real, be personal and grow relationships all while selling more properties."
                  content={
                    <Fragment>
                      <Paragraph>
                        Using a ‘social media sweatshop’ where every post is an
                        uninspired repeat of the same thing, and reflects the
                        same thing other agencies are saying will negatively
                        affect your brand’s perception in the marketplace.
                      </Paragraph>

                      <Paragraph>
                        YYou’re knowledge and experience is what separates you
                        from the pack, and on social media it’s your personality
                        that has to shine through. As a busy agent it can feel
                        like a necessity to farm out the work but we’ve made it
                        so simple and non-invasive that you will finally be able
                        to take the control you need to make an impact.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <Quote
                  agentImage={data.joshPhegan.childImageSharp.fluid}
                  desc={`“Be a real person, and stay focused on the personal connection and your personal relationships through your brand. That will make social really work for you.”`}
                  agentName="Josh Phegan"
                  agencyName="Real Estate Trainer"
                />
              </ContentWrapper>
              <GreyBackground style={{ marginBottom: 0, padding: '10px 0' }}>
                <ContentWrapper>
                  <IntroRow
                    image={data.connectedAndIntegrated.childImageSharp.fluid}
                    title="Connected & Integrated"
                    descWidth="50%"
                    content={
                      <Fragment>
                        <Paragraph>
                          By plugging directly into the Facebook API we’ve
                          enabled even the most inexperienced users to create
                          effective campaigns that deliver results in less time
                          than other methods.
                        </Paragraph>

                        <Paragraph>
                          You’ll be extending your local profile, increasing
                          brand awareness and doing a much better job of
                          capturing the online market in just a few clicks.
                        </Paragraph>

                        <Paragraph>
                          And if you’ve got a marketing co-ordinator for your
                          entire office they’ll be able to access and manage
                          your campaigns on your behalf.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </GreyBackground>
              <ImageBackground
                background={data.powerful.childImageSharp.fluid}
                style={{ height: '450px' }}
                opacity="0.5"
              >
                <ContentWrapper>
                  <IntroRow
                    title={
                      <Fragment>
                        Powerful Technology,
                        <br />
                        <strong>Your Expertise</strong>
                      </Fragment>
                    }
                    descWidth="50%"
                    content={
                      <Fragment>
                        <Paragraph>
                          Spend less, get more with packages{' '}
                          <strong>as low as $150 </strong>
                          and the greatest ad-spend for your dollar in the
                          market.
                        </Paragraph>

                        <Paragraph>
                          Better results for you and your vendor.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper direction="row">
                <FeatureBox
                  image={data.yourself.childImageSharp.fluid}
                  imgLength="35px"
                  title="Do it Yourself"
                  subTitle="Take control, get personal"
                  content={
                    <Paragraph>
                      Save money, control your brand message. Stand out in the
                      market.
                    </Paragraph>
                  }
                />
                <FeatureBox
                  image={data.budgeting.childImageSharp.fluid}
                  imgLength="35px"
                  title="Budgeting"
                  subTitle="Audit Safe Finances"
                  content={
                    <Paragraph>
                      Utilise Realhub’s quoting, invoicing and accounting to
                      keep track of spend and orders.
                    </Paragraph>
                  }
                />

                <FeatureBox
                  image={data.reporting.childImageSharp.fluid}
                  imgLength="35px"
                  title="Reporting"
                  subTitle="Track performance"
                  content={
                    <Paragraph>
                      Statistics are fed back into Realhub from Facebooks
                      platform, show vendors their results.
                    </Paragraph>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  image={data.pin.childImageSharp.fluid}
                  title="You’re the Audience Expert now, demonstrate pinpoint targeting"
                  descWidth="50%"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Choose hands off targeting, crafted to perfection
                          across millions of impressions.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Millions of impressions helped us craft the most
                        effective targeting options available, embedded into the
                        Facebook platform, with all the control you need inside
                        Realhub.
                      </Paragraph>

                      <Paragraph>
                        <strong>
                          Or BYO target audiences and pixel tracking for a
                          bespoke technique.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Bring your own custom audiences and we’ll integrate them
                        directly into Realhub so you can access what you know is
                        already working. Put your hard work to work.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <Img
                  fluid={data.integration.childImageSharp.fluid}
                  style={{ width: '100%', height: '250px' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  title="High quality photos & campaign data is instantly and effortlessly brought across from your CRM into social ads."
                  descWidth="70%"
                  content={
                    <Paragraph>
                      If you’re an existing Realhub customer with your CRM
                      already syncing properties across, all of your data and
                      information is right at your fingertips.
                    </Paragraph>
                  }
                />
              </ContentWrapper>

              <GreyBackground skew>
                <ContentWrapper style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Your <strong>preferred ad types,</strong> from{' '}
                    <strong>facebook’s best</strong> performers.
                  </BannerH4>
                  <BannerH6>
                    A tool so simple, you’ll hit the ground running in no time
                    at all, even with no experience.
                  </BannerH6>
                  <Flex style={{ marginTop: 60 }}>
                    <FeatureBox
                      image={data.singleImage.childImageSharp.fluid}
                      title="Single Image"
                      subTitle="Full size for high quality images"
                      content={
                        <Paragraph>
                          Highlight one large image with extra copy. Keep the
                          focus on 1 image for great for preview & sold ads.
                        </Paragraph>
                      }
                    />
                    <FeatureBox
                      image={data.displayAds.childImageSharp.fluid}
                      title="Display Ads"
                      subTitle="Millions of External Websites"
                      content={
                        <Paragraph>
                          Let facebook control the copy with smart AI that
                          automatically adapts website copy for ads.
                        </Paragraph>
                      }
                    />

                    <FeatureBox
                      image={data.carouselAds.childImageSharp.fluid}
                      title="Carousel Ads"
                      subTitle="Show off an entire property"
                      content={
                        <Paragraph>
                          Show off the whole property with custom titles per
                          image and higher engagement.
                        </Paragraph>
                      }
                    />
                  </Flex>
                </ContentWrapper>
              </GreyBackground>

              <ContentWrapper>
                <IntroRow
                  image={data.multipleNetworks.childImageSharp.fluid}
                  title="Automatic, AI driven serving of your ads across multiple networks."
                  descWidth="50%"
                  content={
                    <Fragment>
                      <Paragraph>
                        Facebook Advertising offers multiple ad placements for
                        your content. The integration with their ad-platforms
                        enables us to access the preferred and algorithmic
                        serving of ads, at the right time and the right place to
                        increase the likelihood someone will click on your ad.
                      </Paragraph>
                      <Paragraph>
                        <strong>Instagram</strong>1 in 3 Australians
                        <br />
                        <strong>Facebook</strong> 1 in 2 Australians use daily
                        <br />
                        <strong>Messenger</strong> More than half of Australia
                        are daily users.
                        <br />
                        <strong>Audience Network </strong>
                        100,000+ websites (1 Billion+ Views)
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.unlimitedUpdates.childImageSharp.fluid}
                  reverse
                  title="Unlimited updates. Change your ads at any time."
                  descWidth="50%"
                  content={
                    <Paragraph>
                      Easy to build and easier to edit, you can control the
                      message on the ad across the life of the campaign to
                      reflect campaign milestones or specific market activity
                      and even promote the sale.
                    </Paragraph>
                  }
                />
              </ContentWrapper>

              <GreyBackground style={{ marginBottom: 0 }}>
                <ContentWrapper style={{ marginTop: 0 }}>
                  <BannerH4>
                    Our <strong>typical results</strong>, across our advertising
                    network. Easy to use, and more money towards ad-spend. Means
                    better results.
                  </BannerH4>
                </ContentWrapper>

                <ContentWrapper direction="row" style={{ marginBottom: 0 }}>
                  <FeatureBox
                    featureText="1050"
                    title="Impressions"
                    subTitle="Displays of your ad"
                    content={
                      <Paragraph>
                        Average number of potential buyers your property is
                        served to, per $1 of ad-spend.
                      </Paragraph>
                    }
                  />
                  <FeatureBox
                    featureText="252,000"
                    title="Impressions"
                    subTitle="Per Campaign ($240 Ad Spend)"
                    content={
                      <Paragraph>
                        The average number of times your ad is displayed across
                        the entire network of placements.
                      </Paragraph>
                    }
                  />

                  <FeatureBox
                    featureText="$240"
                    title="Ad-Spend"
                    subTitle="Average across our customers"
                    content={
                      <Paragraph>
                        Across our thousands of offices the ad-spend we
                        experience our social media customers use is $240.
                      </Paragraph>
                    }
                  />
                </ContentWrapper>

                <ContentWrapper direction="row" style={{ marginTop: 0 }}>
                  <FeatureBox
                    featureText="$2.80"
                    title="CPC"
                    subTitle="Cost per click of your Ad"
                    content={
                      <Paragraph>
                        Average cost to send a potential buyer to your property
                        website or microsite.
                      </Paragraph>
                    }
                  />
                  <FeatureBox
                    featureText="85"
                    title="Buyers"
                    subTitle="Viewers clicking your ad"
                    content={
                      <Paragraph>
                        Average number of potential buyers directed to your
                        property website or microsite.
                      </Paragraph>
                    }
                  />

                  <FeatureBox
                    featureText="63,650"
                    title="Impressions"
                    subTitle="Average across all ad-campaigns"
                    content={
                      <Paragraph>
                        Across all the advertising we’ve placed on facebook we
                        average 63,650 impressions.
                      </Paragraph>
                    }
                  />
                </ContentWrapper>
              </GreyBackground>

              <ImageBackground
                opacity="0"
                style={{ height: '700px' }}
                imgStyle={{ objectPosition: 'left center' }}
                background={data.coorparoo.childImageSharp.fluid}
              >
                <ContentWrapper
                  style={{ color: '#404041', margin: '20px auto' }}
                >
                  <IntroRow
                    descWidth="50%"
                    title="Immediately apparent results, skyrocketing reach and exceptional property interest for Coorparoo"
                    content={
                      <Fragment>
                        <Paragraph>
                          <strong>LJ Hooker Coorparoo</strong> decided to start
                          promoting their properties on facebook for the first
                          time, due to its ease of use in comparison to other
                          tools.
                        </Paragraph>

                        <Paragraph>
                          The results speak for themselves. Not only did their
                          reach and exposure in the marketplace increase{' '}
                          <strong>+1,250%</strong> but the residual effect on
                          their normal page posts saw increases across the
                          board.
                        </Paragraph>
                        <Paragraph>
                          <strong>Post Engagements </strong>
                          saw a 51% increase <br />
                          <strong>Page Reach </strong>
                          saw a 381% increase reaching more than roughly 3,500 a
                          day
                          <br />
                          <strong>Messenger</strong> More than 50% of
                          Australians are daily users, serving ads right where
                          they are looking
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <Img
                  fluid={data.coorparooGraph.childImageSharp.fluid}
                  style={{ width: '70%', height: '250px', margin: 'auto' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ContentWrapper>

              <ContentWrapper direction="row" style={{ marginTop: 0 }}>
                <FeatureBox
                  featureText="$400"
                  title="Ad Spend"
                  subTitle="Total campaign spend"
                  content={
                    <Paragraph>
                      In order to make a real impact and see results that could
                      truly exceed the vendors expectations they went with a
                      high ad-spend option.
                    </Paragraph>
                  }
                />
                <FeatureBox
                  featureText="170"
                  title="Link Clicks"
                  subTitle="Potential Buyers click-through"
                  content={
                    <Paragraph>
                      Over 170 potential buyers clicked through the ad to their
                      own website, exposed to this and all other properties.
                    </Paragraph>
                  }
                />

                <FeatureBox
                  featureText="395,000"
                  title="Impressions"
                  subTitle="Buyers/Sellers exposed to Brand"
                  content={
                    <Paragraph>
                      Almost 400,000 people exposed to their brand, people who
                      could be buyers for that property, or even potential
                      future sellers.
                    </Paragraph>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                opacity="0"
                style={{ height: '700px' }}
                imgStyle={{ objectPosition: 'left center' }}
                background={data.narrabeen.childImageSharp.fluid}
              >
                <ContentWrapper
                  style={{ color: '#404041', margin: '20px auto' }}
                >
                  <IntroRow
                    title={
                      <Fragment>
                        Built for ease of use. Ads built in minutes, results in
                        days for <strong>Narrabeen</strong>.
                      </Fragment>
                    }
                    content={
                      <Fragment>
                        <Paragraph>
                          <strong>LJ Hooker Narrabeen</strong> wanted to
                          increase their digital presence in the marketplace,
                          both to encourage exposure to new leads and help find
                          buyers for their existing properties.
                        </Paragraph>

                        <Paragraph>
                          Across just 4 campaigns with a maximum spend of $120
                          per property they were able to expose their brand, and
                          their properties to over 45,000 potential buyers, with
                          ads placed over 800,000 times and over 200 buyers
                          directed to their property websites.
                        </Paragraph>
                        <Paragraph>
                          <strong>Buyer Clicks</strong> 800,000
                          <br />
                          <strong>Buyer Reach</strong> 45,000
                          <br />
                          <strong>Buyer Clicks</strong> (To company owned saled
                          page) 200
                          <br />
                          <strong>Maximum Ad Spend</strong> $120
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <Img
                  fluid={data.narrabeenGraph.childImageSharp.fluid}
                  style={{ width: '70%', height: '250px', margin: 'auto' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ContentWrapper>

              <ContentWrapper direction="row">
                <FeatureBox
                  featureText="$120"
                  title="Ad Spend"
                  subTitle="Avg. campaign spend"
                  content={
                    <Paragraph>
                      In order to make a substantial impact, while keeping costs
                      affordable for their vendors, LJ Hooker Narrabeen chose
                      conservative ad packages, designed to deliver great
                      results.
                    </Paragraph>
                  }
                />
                <FeatureBox
                  featureText="218"
                  title="Link Clicks"
                  subTitle="Potential Buyers click-through"
                  content={
                    <Paragraph>
                      Over 218 potential buyers clicked through the ads to their
                      own website, exposed to these and all other properties.
                    </Paragraph>
                  }
                />

                <FeatureBox
                  featureText="820,000"
                  title="Impressions"
                  subTitle="Buyers/Sellers exposed to Brand"
                  content={
                    <Paragraph>
                      Almost 900,000 people exposed to their brand, people who
                      could be buyers for these properties, or even potential
                      future sellers.
                    </Paragraph>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Our <strong>loyal team</strong> of{' '}
                    <strong>Australia’s leading</strong> real estate agencies,
                    working <strong>hand in hand</strong> to help build the{' '}
                    <strong>future of real estate.</strong>
                  </BannerH4>
                  <BannerH6 style={{ marginBottom: 0 }}>
                    Input from the largest and smallest real estate businesses
                    in Australia is helping to build the most effective platform
                    for professional agents.
                  </BannerH6>

                  <TypeFormButton />
                </FlexColumn>
              </ContentWrapper>

              <ContentWrapper>
                <ProductBoxes />
              </ContentWrapper>

              <ContentWrapper>
                <SpeakToTeam />
              </ContentWrapper>

              <ContentWrapper>
                <Proof>You’re in good company...</Proof>
                <SubProof>Join our team of loyal customers:</SubProof>

                <BrandBanner />
              </ContentWrapper>

              <ContentWrapper>
                <Quote
                  agentImage={data.johnCunningham.childImageSharp.fluid}
                  desc={`“Sometimes you’ve got to make a quantum leap, you’ve gotta take a leap of faith with innovation and lead the way. Change is inevitable. It’s the only constant. Embrace it, go with it, because if you don’t you’ll just get left behind.”`}
                  agencyName="Cunninghams"
                  title="Founder & Director"
                  agentName="John Cunningham"
                />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default SocialMediaPage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;
  width: 100%;

  ${customMedia.greaterThan('mobile')`
    max-width: 550px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 2;
  margin-bottom: 30px;
  width: 100%;

  ${customMedia.greaterThan('mobile')`
    width: 450px;
  `};
`

const HeaderPhoneImg = styled(Img)`
  position: absolute;
  overflow: hidden;
  width: 250px;
  height: 250px;
  bottom: -90px;
  right: 0;

  ${customMedia.between('mobile', 'tablet')`
    width: 350px;
    height: 250px;
    bottom: -80px;
    right: 0px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 350px;
    height: 350px;
    bottom: -100px;
    right: -100px;
  `};
`

const HeaderLink = styled.a`
  font-family: Caveat;
  color: #02b7e2;
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  width: 100%;

  ${customMedia.greaterThan('desktop')`
    margin-bottom: 10px;
  `};
`
const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-left: 5px;
`

const Paragraph = styled.p`
  font-family: ${props => props.fontFamily || 'Open Sans'};
  margin-bottom: 30px;
`

const Proof = styled.div`
  font-size: 25px;
  margin-bottom: 15px;
`

const SubProof = styled.div`
  font-size: 16px;
  color: #8c8c8e;
  margin-bottom: 30px;
`

const MoonImg = styled(Img)`
  position: absolute;
  overflow: hidden;
  width: 300px;
  height: 300px;
  bottom: -120px;
  right: 20px;

  ${customMedia.between('mobile', 'tablet')`
    width: 350px;
    height: 350px;
    bottom: -150px;
    right: 50px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 450px;
    height: 450px;
    bottom: -200px;
    right: -40px;
  `};
`
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
